// Use this variable to set up the common and page specific functions. If you
// rename this variable, you will also need to rename the namespace below.
var Sage = {
    // All pages
    'common': {
        init: function () {
            // JavaScript to be fired on all pages
            $(".owl-carousel#slider").owlCarousel({
                responsiveClass: true,
                items: 1,
                autowidth: true,

                animateOut: 'fadeOut',
                smartSpeed: 450,
                nav: true,
                dots: true,
                loop: true,
                autoplay: true
            });

            $(window).scroll(function () {
                if ($(document).scrollTop() > 90) {
                    $('.header-sticky').fadeIn();
                    $('.container-fluid').addClass('header-sticky-container');

                } else {
                    $('.header-sticky').fadeOut(200);
                    $('.container-fluid').removeClass('header-sticky-container');

                }
            });

            $('.menu-self-build-quote-form').click(function () {
                $("html, body").animate({scrollTop: 0}, "slow");
                return false;
            });

            $(".owl-carousel#logos").owlCarousel({
                responsiveClass: true,
                autowidth: true,

                animateOut: 'fadeOut',
                smartSpeed: 450,
                nav: true,
                dots: true,
                loop: true,
                responsive: {
                    0: {
                        items: 3,
                        nav: true,
                        loop: true
                    },
                    450: {
                        items: 5,
                        nav: true,
                        loop: true

                    },
                    1000: {
                        margin: 0,
                        items: 10,
                        nav: false,
                        loop: true
                    }
                }
            });

        },
        finalize: function () {
            // JavaScript to be fired on all pages, after page specific JS is fired

        }
    },
    // Home page
    'home': {
        init: function () {
            $(".owl-carousel#range").owlCarousel({
                responsiveClass: true,
                margin: 15,
                autowidth: true,
                responsive: {
                    0: {
                        items: 1,
                        nav: true,
                        loop: true
                    },
                    450: {
                        items: 2,
                        nav: true,
                        loop: true
                    },
                    1000: {
                        margin: 5,
                        items: 4,
                        nav: false,
                        loop: false
                    }
                }
            });
        },
        finalize: function () {
            // JavaScript to be fired on the home page, after the init JS
        }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
        init: function () {
            // JavaScript to be fired on the about us page
            $(".owl-carousel#range").owlCarousel({
                responsiveClass: true,
                margin: 15,
                autowidth: true,
                responsive: {
                    0: {
                        items: 1,
                        nav: true,
                        loop: true
                    },
                    450: {
                        items: 2,
                        nav: true,
                        loop: true
                    },
                    1000: {
                        margin: 5,
                        items: 4,
                        nav: false,
                        loop: false
                    }
                }
            });
            $(".owl-carousel#tick-boxes").owlCarousel({
                responsiveClass: true,
                autowidth: true,

                animateOut: 'fadeOut',
                smartSpeed: 450,
                nav: true,
                dots: true,
                loop: true,
                responsive: {
                    0: {
                        items: 1,
                        nav: true,
                        loop: true
                    },
                    450: {
                        items: 2,
                        nav: true,
                        loop: true
                    },
                    600: {
                        items: 3,
                        nav: true,
                        loop: true
                    },
                    768: {
                        items: 4,
                        nav: true,
                        loop: true

                    },
                    1000: {
                        margin: 10,
                        items: 5,
                        nav: false,
                        loop: true
                    }
                }
            });

            $(".owl-item > div:odd").addClass('odd');
        }
    },

    'bi_fold_doors': {
        init: function () {
            // JavaScript to be fired on the bi-fold doors page
            $(".owl-carousel#quick-links").owlCarousel({
                responsiveClass: true,
                margin: 15,
                autowidth: true,
                responsive: {
                    0: {
                        items: 1,
                        nav: true,
                        loop: true
                    },
                    768: {
                        items: 2,
                        nav: true,
                        loop: true
                    },
                    1000: {
                        items: 3,
                        loop: true
                    }
                }
            });

            $(".owl-carousel#three-steps").owlCarousel({
                responsiveClass: true,
                autowidth: true,
                responsive: {
                    0: {
                        items: 1,
                        nav: true,
                        loop: true
                    },
                    768: {
                        items: 2,
                        nav: true,
                        loop: true,
                        margin: 5
                    },
                    1000: {
                        items: 3,
                        loop: true,
                        margin: 5
                    }
                }
            });
        }
    },

    'self_build_diy_conservatories': {
        init: function () {
            // JavaScript to be fired on the diy-self-build doors page
            $(".owl-carousel#self-build-block").owlCarousel({
                responsiveClass: true,
                autowidth: true,

                animateOut: 'fadeOut',
                smartSpeed: 450,
                dots: true,
                loop: true,
                responsive: {
                    0: {
                        items: 1,
                        nav: true,
                        loop: true
                    },
                    768: {
                        items: 2,
                        nav: true,
                        loop: true,
                        margin: 5
                    },
                    992: {
                        items: 3,
                        loop: true,
                        margin: 5
                    }
                }
            });

            $(".owl-carousel#quick-links").owlCarousel({
                responsiveClass: true,
                margin: 15,
                autowidth: true,
                responsive: {
                    0: {
                        items: 1,
                        nav: true,
                        loop: true
                    },
                    768: {
                        items: 2,
                        nav: true,
                        loop: true
                    },
                    1000: {
                        items: 3,
                        loop: true
                    }
                }
            });

            $(".owl-carousel#five-steps").owlCarousel({
                responsiveClass: true,
                autowidth: true,
                responsive: {
                    0: {
                        items: 1,
                        nav: true,
                        loop: true
                    },
                    768: {
                        items: 3,
                        nav: true,
                        loop: true,
                        margin: 5
                    },
                    1000: {
                        items: 5,
                        loop: true,
                        margin: 5
                    }
                }
            });
        }
    },

    'replacement_roofs': {
        init: function () {
            // JavaScript to be fired on the replacement roofs page
            $(".owl-carousel#replacement-roofs-block").owlCarousel({
                responsiveClass: true,
                autowidth: true,

                animateOut: 'fadeOut',
                smartSpeed: 450,
                dots: true,
                loop: true,
                responsive: {
                    0: {
                        items: 1,
                        nav: true,
                        loop: true
                    },
                    768: {
                        items: 2,
                        loop: true,
                        margin: 20
                    }
                }
            });

            $(".owl-carousel#quick-links").owlCarousel({
                responsiveClass: true,
                margin: 15,
                autowidth: true,
                responsive: {
                    0: {
                        items: 1,
                        nav: true,
                        loop: true
                    },
                    768: {
                        items: 2,
                        nav: true,
                        loop: true
                    },
                    1000: {
                        items: 3,
                        loop: true
                    }
                }
            });

            $(".owl-carousel#five-steps").owlCarousel({
                responsiveClass: true,
                autowidth: true,
                responsive: {
                    0: {
                        items: 1,
                        nav: true,
                        loop: true
                    },
                    768: {
                        items: 3,
                        nav: true,
                        loop: true,
                        margin: 5
                    },
                    1000: {
                        items: 5,
                        loop: true,
                        margin: 0
                    }
                }
            });

            $(".owl-carousel#discover-range").owlCarousel({
                responsiveClass: true,
                autowidth: true,
                responsive: {
                    0: {
                        items: 1,
                        nav: true,
                        loop: true
                    },
                    768: {
                        items: 3,
                        loop: true,
                        margin: 5
                    }
                }
            });
        }
    },

    'lantern_roofs': {
        init: function () {

            // JavaScript to be fired on the lantern roofs page
            $(".owl-carousel#lantern-roofs-block").owlCarousel({
                responsiveClass: true,
                autowidth: true,

                animateOut: 'fadeOut',
                smartSpeed: 450,
                dots: true,
                loop: true,
                responsive: {
                    0: {
                        items: 1,
                        nav: true,
                        loop: true
                    },
                    768: {
                        items: 2,
                        loop: true,
                        margin: 20
                    }
                }
            });

            $(".owl-carousel#quick-links").owlCarousel({
                responsiveClass: true,
                margin: 15,
                autowidth: true,
                responsive: {
                    0: {
                        items: 1,
                        nav: true,
                        loop: true
                    },
                    768: {
                        items: 2,
                        nav: true,
                        loop: true
                    },
                    1000: {
                        items: 3,
                        loop: true
                    }
                }
            });

            $(".owl-carousel#five-steps").owlCarousel({
                responsiveClass: true,
                autowidth: true,
                responsive: {
                    0: {
                        items: 1,
                        nav: true,
                        loop: true
                    },
                    768: {
                        items: 3,
                        nav: true,
                        loop: true,
                        margin: 5
                    },
                    1000: {
                        items: 4,
                        loop: true,
                        margin: 0
                    }
                }
            });

            $(".owl-carousel#discover-range").owlCarousel({
                responsiveClass: true,
                autowidth: false,
                responsive: {
                    0: {
                        items: 1,
                        nav: true,
                        loop: true
                    },
                    768: {
                        items: 3,
                        loop: true,
                        margin: 5
                    }
                }
            });
        }
    },

    'helpful_tips_and_advice': {
        init: function () {

            // Script to allow correct tab to be shown from external link...
            var url = document.location.toString();

            if (url.match('#')) {
                $('.nav-tabs a[href=#' + url.split('#')[1] + ']').tab('show');
                $('html, body').animate({
                    scrollTop: 600
                }, 'slow');
            }

            $(window).on('resize', function () {
                var windowWidth = $(window).width() - 20;
                if (windowWidth < 1346 && windowWidth > 992 ) {
                    $('.gallery-modal.in .slider-container').width(windowWidth);
                    $('.gallery-modal.in .slider-container .slides .slide').width(windowWidth);
                    $('.gallery-modal.in .slider-container .slides .slide img').width(windowWidth);
                    // $('.gallery-modal.in .modal-body').width(windowWidth);

                    $('.gallery-modal.in .slider-container').css({height: '100%'});
                    $('.gallery-modal.in .slider-container .slides .slide').css({height: '100%'});
                    $('.gallery-modal.in .slider-container .slides .slide img').css({height: 'auto'});
                }
            });

            //FUNCTION TO GET AND AUTO PLAY YOUTUBE VIDEO FROM DATATAG
            function autoPlayYouTubeModal() {
                var trigger = $("body").find('[data-toggle="modal"]');
                trigger.click(function () {
                    var theModal = $(this).data("target"),
                        videoSRC = $(this).attr("data-theVideo"),
                        videoSRCauto = videoSRC + "?autoplay=1&fs=1&modestbranding=1";
                    $(theModal + ' iframe').attr('src', videoSRCauto);
                    $(theModal + ' button.close').click(function () {
                        $(theModal + ' iframe').attr('src', videoSRC);
                    });
                    $('.modal').click(function () {
                        $(theModal + ' iframe').attr('src', videoSRC);
                    });
                });
            }
            autoPlayYouTubeModal();

        },

        finalize: function () {
            // JavaScript to be fired on all pages, after page specific JS is fired

        }
    },

    'project_inspiration': {
        init: function () {

            $(".owl-carousel.project-inspiration-block").owlCarousel({
                responsiveClass: true,
                autowidth: true,

                animateOut: 'fadeOut',
                smartSpeed: 450,
                dots: true,
                loop: true,
                responsive: {
                    0: {
                        items: 1,
                        nav: true,
                        loop: true
                    },
                    600: {
                        items: 2,
                        nav: true,
                        loop: true,
                        margin: 5
                    },
                    992: {
                        items: 4,
                        loop: true,
                        margin: 5
                    }
                }
            });
        }
    },

    'atlas_glazed_roofs': {
        init: function () {

            // JavaScript to be fired on the Atlas Glazed roofs page

            $(".owl-carousel#quick-links").owlCarousel({
                responsiveClass: true,
                margin: 15,
                autowidth: true,
                responsive: {
                    0: {
                        items: 1,
                        nav: true,
                        loop: true
                    },
                    768: {
                        items: 2,
                        nav: true,
                        loop: true
                    },
                    1000: {
                        items: 3,
                        loop: true
                    }
                }
            });

            $(".owl-carousel#discover-range").owlCarousel({
                responsiveClass: true,
                autowidth: false,
                responsive: {
                    0: {
                        items: 1,
                        nav: true,
                        loop: true
                    },
                    550: {
                        items: 2,
                        nav: true,
                        loop: true
                    },
                    768: {
                        items: 4,
                        loop: true,
                        margin: 5
                    }
                }
            });

            $(".owl-carousel#three-steps").owlCarousel({
                responsiveClass: true,
                autowidth: true,
                responsive: {
                    0: {
                        items: 1,
                        nav: true,
                        loop: true
                    },
                    768: {
                        items: 2,
                        nav: true,
                        loop: true,
                        margin: 5
                    },
                    1000: {
                        items: 3,
                        loop: true,
                        margin: 5
                    }
                }
            });
        }
    },

    'k2_lantern_roofs': {
        init: function () {

            // JavaScript to be fired on the K2 Lantern roofs page

            $(".owl-carousel#quick-links").owlCarousel({
                responsiveClass: true,
                margin: 15,
                autowidth: true,
                responsive: {
                    0: {
                        items: 1,
                        nav: true,
                        loop: true
                    },
                    768: {
                        items: 2,
                        nav: true,
                        loop: true
                    },
                    1000: {
                        items: 3,
                        loop: true
                    }
                }
            });

            $(".owl-carousel#discover-range").owlCarousel({
                responsiveClass: true,
                autowidth: false,
                responsive: {
                    0: {
                        items: 1,
                        nav: true,
                        loop: true
                    },
                    550: {
                        items: 2,
                        nav: true,
                        loop: true
                    },
                    768: {
                        items: 4,
                        loop: true,
                        margin: 5
                    }
                }
            });

            $(".owl-carousel#three-steps").owlCarousel({
                responsiveClass: true,
                autowidth: true,
                responsive: {
                    0: {
                        items: 1,
                        nav: true,
                        loop: true
                    },
                    768: {
                        items: 2,
                        nav: true,
                        loop: true,
                        margin: 5
                    },
                    1000: {
                        items: 3,
                        loop: true,
                        margin: 5
                    }
                }
            });
        }
    },

    'replacement_glass_conservatory_roofs': {
        init: function () {

            // JavaScript to be fired on the glass conservatory roofs page

            $(".owl-carousel#quick-links").owlCarousel({
                responsiveClass: true,
                margin: 15,
                autowidth: true,
                responsive: {
                    0: {
                        items: 1,
                        nav: true,
                        loop: true
                    },
                    768: {
                        items: 2,
                        nav: true,
                        loop: true
                    },
                    1000: {
                        items: 3,
                        loop: true
                    }
                }
            });

            $(".owl-carousel#discover-range").owlCarousel({
                responsiveClass: true,
                autowidth: false,
                responsive: {
                    0: {
                        items: 1,
                        nav: true,
                        loop: true
                    },
                    550: {
                        items: 2,
                        nav: true,
                        loop: true
                    },
                    768: {
                        items: 4,
                        loop: true,
                        margin: 5
                    }
                }
            });

            $(".owl-carousel#three-steps").owlCarousel({
                responsiveClass: true,
                autowidth: true,
                responsive: {
                    0: {
                        items: 1,
                        nav: true,
                        loop: true
                    },
                    768: {
                        items: 2,
                        nav: true,
                        loop: true,
                        margin: 5
                    },
                    1000: {
                        items: 3,
                        loop: true,
                        margin: 5
                    }
                }
            });
        }
    },

    'replacement_solid_warm_roofs': {
        init: function () {

            // JavaScript to be fired on the solid warm roofs page

            $(".owl-carousel#quick-links").owlCarousel({
                responsiveClass: true,
                margin: 15,
                autowidth: true,
                responsive: {
                    0: {
                        items: 1,
                        nav: true,
                        loop: true
                    },
                    768: {
                        items: 2,
                        nav: true,
                        loop: true
                    },
                    1000: {
                        items: 3,
                        loop: true
                    }
                }
            });

            $(".owl-carousel#discover-range").owlCarousel({
                responsiveClass: true,
                autowidth: false,
                responsive: {
                    0: {
                        items: 1,
                        nav: true,
                        loop: true
                    },
                    550: {
                        items: 2,
                        nav: true,
                        loop: true
                    },
                    768: {
                        items: 4,
                        loop: true,
                        margin: 5
                    }
                }
            });

            $(".owl-carousel#three-steps").owlCarousel({
                responsiveClass: true,
                autowidth: true,
                responsive: {
                    0: {
                        items: 1,
                        nav: true,
                        loop: true
                    },
                    768: {
                        items: 2,
                        nav: true,
                        loop: true,
                        margin: 5
                    },
                    1000: {
                        items: 3,
                        loop: true,
                        margin: 5
                    }
                }
            });
        }
    },

    'edwardian_conservatories': {
        init: function () {

            // JavaScript to be fired on the solid warm roofs page

            $(".owl-carousel#quick-links").owlCarousel({
                responsiveClass: true,
                margin: 15,
                autowidth: true,
                responsive: {
                    0: {
                        items: 1,
                        nav: true,
                        loop: true
                    },
                    768: {
                        items: 2,
                        nav: true,
                        loop: true
                    },
                    1000: {
                        items: 3,
                        loop: true
                    }
                }
            });

            $(".owl-carousel#discover-range").owlCarousel({
                responsiveClass: true,
                autowidth: false,
                responsive: {
                    0: {
                        items: 1,
                        nav: true,
                        loop: true
                    },
                    550: {
                        items: 2,
                        nav: true,
                        loop: true
                    },
                    768: {
                        items: 5,
                        loop: true,
                        margin: 5
                    }
                }
            });

            $(".owl-carousel#three-steps").owlCarousel({
                responsiveClass: true,
                autowidth: true,
                responsive: {
                    0: {
                        items: 1,
                        nav: true,
                        loop: true
                    },
                    768: {
                        items: 2,
                        nav: true,
                        loop: true,
                        margin: 5
                    },
                    1000: {
                        items: 3,
                        loop: true,
                        margin: 5
                    }
                }
            });
        }
    },

    'lean_to_conservatories': {
        init: function () {

            // JavaScript to be fired on the solid warm roofs page

            $(".owl-carousel#quick-links").owlCarousel({
                responsiveClass: true,
                margin: 15,
                autowidth: true,
                responsive: {
                    0: {
                        items: 1,
                        nav: true,
                        loop: true
                    },
                    768: {
                        items: 2,
                        nav: true,
                        loop: true
                    },
                    1000: {
                        items: 3,
                        loop: true
                    }
                }
            });

            $(".owl-carousel#discover-range").owlCarousel({
                responsiveClass: true,
                autowidth: false,
                responsive: {
                    0: {
                        items: 1,
                        nav: true,
                        loop: true
                    },
                    550: {
                        items: 2,
                        nav: true,
                        loop: true
                    },
                    768: {
                        items: 5,
                        loop: true,
                        margin: 5
                    }
                }
            });

            $(".owl-carousel#three-steps").owlCarousel({
                responsiveClass: true,
                autowidth: true,
                responsive: {
                    0: {
                        items: 1,
                        nav: true,
                        loop: true
                    },
                    768: {
                        items: 2,
                        nav: true,
                        loop: true,
                        margin: 5
                    },
                    1000: {
                        items: 3,
                        loop: true,
                        margin: 5
                    }
                }
            });
        }
    },

    'pavilion_conservatories': {
        init: function () {

            // JavaScript to be fired on the solid warm roofs page

            $(".owl-carousel#quick-links").owlCarousel({
                responsiveClass: true,
                margin: 15,
                autowidth: true,
                responsive: {
                    0: {
                        items: 1,
                        nav: true,
                        loop: true
                    },
                    768: {
                        items: 2,
                        nav: true,
                        loop: true
                    },
                    1000: {
                        items: 3,
                        loop: true
                    }
                }
            });

            $(".owl-carousel#discover-range").owlCarousel({
                responsiveClass: true,
                autowidth: false,
                responsive: {
                    0: {
                        items: 1,
                        nav: true,
                        loop: true
                    },
                    550: {
                        items: 2,
                        nav: true,
                        loop: true
                    },
                    768: {
                        items: 5,
                        loop: true,
                        margin: 5
                    }
                }
            });

            $(".owl-carousel#three-steps").owlCarousel({
                responsiveClass: true,
                autowidth: true,
                responsive: {
                    0: {
                        items: 1,
                        nav: true,
                        loop: true
                    },
                    768: {
                        items: 2,
                        nav: true,
                        loop: true,
                        margin: 5
                    },
                    1000: {
                        items: 3,
                        loop: true,
                        margin: 5
                    }
                }
            });
        }
    },

    'victorian_conservatories': {
        init: function () {

            // JavaScript to be fired on the solid warm roofs page

            $(".owl-carousel#quick-links").owlCarousel({
                responsiveClass: true,
                margin: 15,
                autowidth: true,
                responsive: {
                    0: {
                        items: 1,
                        nav: true,
                        loop: true
                    },
                    768: {
                        items: 2,
                        nav: true,
                        loop: true
                    },
                    1000: {
                        items: 3,
                        loop: true
                    }
                }
            });

            $(".owl-carousel#discover-range").owlCarousel({
                responsiveClass: true,
                autowidth: false,
                responsive: {
                    0: {
                        items: 1,
                        nav: true,
                        loop: true
                    },
                    550: {
                        items: 2,
                        nav: true,
                        loop: true
                    },
                    768: {
                        items: 5,
                        loop: true,
                        margin: 5
                    }
                }
            });

            $(".owl-carousel#three-steps").owlCarousel({
                responsiveClass: true,
                autowidth: true,
                responsive: {
                    0: {
                        items: 1,
                        nav: true,
                        loop: true
                    },
                    768: {
                        items: 2,
                        nav: true,
                        loop: true,
                        margin: 5
                    },
                    1000: {
                        items: 3,
                        loop: true,
                        margin: 5
                    }
                }
            });
        }
    },

    'capella_orangery': {
        init: function () {

            // JavaScript to be fired on the solid warm roofs page

            $(".owl-carousel#quick-links").owlCarousel({
                responsiveClass: true,
                margin: 15,
                autowidth: true,
                responsive: {
                    0: {
                        items: 1,
                        nav: true,
                        loop: true
                    },
                    768: {
                        items: 2,
                        nav: true,
                        loop: true
                    },
                    1000: {
                        items: 3,
                        loop: true
                    }
                }
            });

            $(".owl-carousel#discover-range").owlCarousel({
                responsiveClass: true,
                autowidth: false,
                responsive: {
                    0: {
                        items: 1,
                        nav: true,
                        loop: true
                    },
                    550: {
                        items: 2,
                        nav: true,
                        loop: true
                    },
                    768: {
                        items: 5,
                        loop: true,
                        margin: 5
                    }
                }
            });

            $(".owl-carousel#three-steps").owlCarousel({
                responsiveClass: true,
                autowidth: true,
                responsive: {
                    0: {
                        items: 1,
                        nav: true,
                        loop: true
                    },
                    768: {
                        items: 2,
                        nav: true,
                        loop: true,
                        margin: 5
                    },
                    1000: {
                        items: 3,
                        loop: true,
                        margin: 5
                    }
                }
            });
        }
    },

    'gullwing_conservatories': {
        init: function () {

            // JavaScript to be fired on the solid warm roofs page

            $(".owl-carousel#quick-links").owlCarousel({
                responsiveClass: true,
                margin: 15,
                autowidth: true,
                responsive: {
                    0: {
                        items: 1,
                        nav: true,
                        loop: true
                    },
                    768: {
                        items: 2,
                        nav: true,
                        loop: true
                    },
                    1000: {
                        items: 3,
                        loop: true
                    }
                }
            });

            $(".owl-carousel#discover-range").owlCarousel({
                responsiveClass: true,
                autowidth: false,
                responsive: {
                    0: {
                        items: 1,
                        nav: true,
                        loop: true
                    },
                    550: {
                        items: 2,
                        nav: true,
                        loop: true
                    },
                    768: {
                        items: 5,
                        loop: true,
                        margin: 5
                    }
                }
            });

            $(".owl-carousel#three-steps").owlCarousel({
                responsiveClass: true,
                autowidth: true,
                responsive: {
                    0: {
                        items: 1,
                        nav: true,
                        loop: true
                    },
                    768: {
                        items: 2,
                        nav: true,
                        loop: true,
                        margin: 5
                    },
                    1000: {
                        items: 3,
                        loop: true,
                        margin: 5
                    }
                }
            });
        }
    },

    // Contact_us
    'contact_us': {
        init: function () {
            // JavaScript to be fired on the about us page
            $(".owl-carousel#range").owlCarousel({
                responsiveClass: true,
                margin: 15,
                autowidth: true,
                responsive: {
                    0: {
                        items: 1,
                        nav: true,
                        loop: true
                    },
                    450: {
                        items: 2,
                        nav: true,
                        loop: true
                    },
                    1000: {
                        margin: 5,
                        items: 4,
                        nav: false,
                        loop: false
                    }
                }
            });


            $(".owl-carousel#tick-boxes").owlCarousel({
                responsiveClass: true,
                autowidth: true,

                animateOut: 'fadeOut',
                smartSpeed: 450,
                nav: true,
                dots: true,
                loop: true,
                responsive: {
                    0: {
                        items: 1,
                        nav: true,
                        loop: true
                    },
                    450: {
                        items: 2,
                        nav: true,
                        loop: true
                    },
                    600: {
                        items: 3,
                        nav: true,
                        loop: true
                    },
                    768: {
                        items: 4,
                        nav: true,
                        loop: true

                    },
                    1000: {
                        margin: 10,
                        items: 5,
                        nav: false,
                        loop: true
                    }
                }
            });

            $(".owl-item > div:odd").addClass('odd');
        }
    },

    // Blog
    'blog': {
        init: function () {

            // JavaScript to be fired on the blog page
            $(".owl-carousel#range").owlCarousel({
                responsiveClass: true,
                margin: 15,
                autowidth: true,
                responsive: {
                    0: {
                        items: 1,
                        nav: true,
                        loop: true
                    },
                    450: {
                        items: 2,
                        nav: true,
                        loop: true
                    },
                    1000: {
                        margin: 5,
                        items: 4,
                        nav: false,
                        loop: false
                    }
                }
            });
            $(".owl-item > div:odd").addClass('odd');

            $(".owl-carousel.recent-posts").owlCarousel({
                responsiveClass: true,
                autowidth: true,

                animateOut: 'fadeOut',
                smartSpeed: 450,
                dots: true,
                loop: true,
                responsive: {
                    0: {
                        items: 1,
                        nav: true,
                        loop: true
                    },
                    600: {
                        items: 2,
                        nav: true,
                        loop: true,
                        margin: 5
                    },
                    992: {
                        items: 4,
                        loop: true,
                        margin: 5
                    }
                }
            });
        }
    },


    // Approved Suppliers
    'approved_suppliers': {
        init: function () {

            // JavaScript to be fired on the blog page

            $(".owl-carousel#approved-supplier-description").owlCarousel({
                responsiveClass: true,
                autowidth: false,
                responsive: {
                    0: {
                        items: 1,
                        nav: true,
                        loop: true
                    },
                    550: {
                        items: 2,
                        nav: true,
                        loop: true
                    },
                    768: {
                        items: 4,
                        loop: true,
                        margin: 5
                    }
                }
            });
            $(".owl-item > div:odd").addClass('odd');
        }
    }


};


/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 *
 * Google CDN, Latest jQuery
 * To use the default WordPress version of jQuery, go to lib/config.php and
 * remove or comment out: add_theme_support('jquery-cdn');
 * ======================================================================== */
(function ($) {


    // The routing fires all common scripts, followed by the page specific scripts.
    // Add additional events for more control over timing e.g. a finalize event
    var UTIL = {
        fire: function (func, funcname, args) {
            var fire;
            var namespace = Sage;
            funcname = (funcname === undefined) ? 'init' : funcname;
            fire = func !== '';
            fire = fire && namespace[func];
            fire = fire && typeof namespace[func][funcname] === 'function';

            if (fire) {
                namespace[func][funcname](args);
            }
        },
        loadEvents: function () {
            // Fire common init JS
            UTIL.fire('common');

            // Fire page-specific init JS, and then finalize JS
            $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function (i, classnm) {
                UTIL.fire(classnm);
                UTIL.fire(classnm, 'finalize');
            });

            // Fire common finalize JS
            UTIL.fire('common', 'finalize');
        }
    };

    // Load Events
    $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
